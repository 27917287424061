import axios from 'axios'

//const API_URL = 'https://ministrycheckin-api.local/'
const API_URL = 'https://api.ministrycheckin.com'

class AuthService {
  async login(credentials) {
    const response = await axios.post(`${API_URL}/auth/login.php`, credentials)
    if (response.data.success) {
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
    }
    return response.data
  }

  logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }

  async register(user) {
    const response = await axios.post(`${API_URL}/auth/register.php`, user)
    if (response.data.success) {
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
    }
    return response.data
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  getToken() {
    return localStorage.getItem('token')
  }

  isLoggedIn() {
    return !!this.getToken()
  }

  // New methods for password reset
  async requestPasswordReset(email) {
    const response = await axios.post(`${API_URL}/auth/forgot-password.php`, { email })
    return response.data
  }

  async verifyResetToken(token) {
    const response = await axios.get(`${API_URL}/auth/verify-reset-token.php?token=${token}`)
    return response.data
  }

  async resetPassword(token, password) {
    const response = await axios.post(`${API_URL}/auth/reset-password.php`, { 
      token, 
      password 
    })
    return response.data
  }
}

export default new AuthService()
