<template>
  <div>
    <h3>Register</h3>
    <form @submit.prevent="handleRegister">
      <div class="form-group mb-3">
        <input v-model="registerData.email" type="email" class="form-control"
          :class="{ 'is-invalid': v$.registerData.email.$error }" placeholder="Email">
        <div class="invalid-feedback" v-if="v$.registerData.email.$error">
          {{ v$.registerData.email.$errors[0].$message }}
        </div>
      </div>
      <div class="form-group mb-3">
        <input v-model="registerData.password" type="password" class="form-control"
          :class="{ 'is-invalid': v$.registerData.password.$error }" placeholder="Password">
        <div class="invalid-feedback" v-if="v$.registerData.password.$error">
          {{ v$.registerData.password.$errors[0].$message }}
        </div>
      </div>
      <div class="form-group mb-3">
        <input v-model="registerData.confirmPassword" type="password" class="form-control"
          :class="{ 'is-invalid': v$.registerData.confirmPassword.$error }" placeholder="Confirm Password">
        <div class="invalid-feedback" v-if="v$.registerData.confirmPassword.$error">
          {{ v$.registerData.confirmPassword.$errors[0].$message }}
        </div>
      </div>
      <button type="submit" class="btn btn-success">Register</button>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import AuthService from '@/services/auth.service'

const emit = defineEmits(['register-success'])

const registerData = ref({
  email: '',
  password: '',
  confirmPassword: ''
})

const rules = computed(() => ({
  registerData: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
    confirmPassword: { required, sameAs: sameAs(registerData.value.password) }
  }
}))

const v$ = useVuelidate(rules, { registerData })

const handleRegister = async () => {
  const isFormValid = await v$.value.registerData.$validate()
  if (!isFormValid) return

  try {
    await AuthService.register({
      email: registerData.value.email,
      password: registerData.value.password
    })
    registerData.value = { email: '', password: '', confirmPassword: '' }
    v$.value.registerData.$reset()
    emit('register-success')
  } catch (error) {
    console.error('Registration failed:', error)
  }
}
</script>
