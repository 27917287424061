import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import ResetPassword from '../components/ResetPassword.vue'
import KidsCheckin from '../components/KidsCheckin.vue'
import AuthService from '../services/auth.service'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { requiresAuth: false }
  },
  {
    path: '/checkin',
    name: 'KidsCheckin',
    component: KidsCheckin,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Navigation guard to check authentication
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/reset-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = AuthService.isLoggedIn()

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})

export default router
