<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card mt-5">
          <div class="card-header">
            <h3 class="text-center">Reset Your Password</h3>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center my-4">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p class="mt-2">Verifying your reset link...</p>
            </div>

            <div v-else-if="error" class="alert alert-danger">
              {{ error }}
            </div>

            <div v-else-if="success" class="alert alert-success">
              <p>{{ success }}</p>
              <div class="text-center mt-3">
                <router-link to="/login" class="btn btn-primary">Go to Login</router-link>
              </div>
            </div>

            <form v-else @submit.prevent="resetPassword">
              <div class="mb-3">
                <label for="password" class="form-label">New Password</label>
                <input 
                  type="password" 
                  class="form-control" 
                  id="password" 
                  v-model="password"
                  :class="{ 'is-invalid': v$.password.$error }"
                >
                <div class="invalid-feedback" v-if="v$.password.$error">
                  {{ v$.password.$errors[0].$message }}
                </div>
              </div>
              <div class="mb-3">
                <label for="confirmPassword" class="form-label">Confirm Password</label>
                <input 
                  type="password" 
                  class="form-control" 
                  id="confirmPassword" 
                  v-model="confirmPassword"
                  :class="{ 'is-invalid': v$.confirmPassword.$error }"
                >
                <div class="invalid-feedback" v-if="v$.confirmPassword.$error">
                  {{ v$.confirmPassword.$errors[0].$message }}
                </div>
              </div>
              <div class="d-grid">
                <button type="submit" class="btn btn-primary" :disabled="resetLoading">
                  <span v-if="resetLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import AuthService from '@/services/auth.service'

const route = useRoute()
const router = useRouter()

const token = ref('')
const loading = ref(true)
const error = ref('')
const success = ref('')
const password = ref('')
const confirmPassword = ref('')
const resetLoading = ref(false)

const rules = computed(() => ({
  password: { 
    required, 
    minLength: minLength(6) 
  },
  confirmPassword: { 
    required, 
    sameAsPassword: sameAs(password.value) 
  }
}))

const v$ = useVuelidate(rules, { password, confirmPassword })

onMounted(async () => {
  // Get token from URL query parameter
  token.value = route.query.token

  if (!token.value) {
    error.value = 'Invalid reset link. No token provided.'
    loading.value = false
    return
  }

  try {
    // Verify the token
    const response = await AuthService.verifyResetToken(token.value)
    if (response.success) {
      loading.value = false
    } else {
      error.value = response.message || 'Invalid or expired reset link.'
      loading.value = false
    }
  } catch (err) {
    error.value = 'Error verifying reset link. Please try again.'
    loading.value = false
  }
})

const resetPassword = async () => {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) return

  resetLoading.value = true

  try {
    const response = await AuthService.resetPassword(token.value, password.value)
    if (response.success) {
      success.value = response.message || 'Your password has been reset successfully.'
      password.value = ''
      confirmPassword.value = ''
      v$.value.$reset()
    } else {
      error.value = response.message || 'Failed to reset password. Please try again.'
    }
  } catch (err) {
    error.value = err.response?.data?.message || 'Error resetting password. Please try again.'
  } finally {
    resetLoading.value = false
  }
}
</script>
