<template>
  <div>
    <h3>Login</h3>
    <form @submit.prevent="handleLogin">
      <div class="form-group mb-3">
        <input v-model="credentials.email" type="email" class="form-control"
          :class="{ 'is-invalid': v$.credentials.email.$error }" placeholder="Email">
        <div class="invalid-feedback" v-if="v$.credentials.email.$error">
          {{ v$.credentials.email.$errors[0].$message }}
        </div>
      </div>
      <div class="form-group mb-3">
        <input v-model="credentials.password" type="password" class="form-control"
          :class="{ 'is-invalid': v$.credentials.password.$error }" placeholder="Password">
        <div class="invalid-feedback" v-if="v$.credentials.password.$error">
          {{ v$.credentials.password.$errors[0].$message }}
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <button type="submit" class="btn btn-primary">Login</button>
        <a href="#" @click.prevent="showForgotPassword = true" class="text-decoration-none">Forgot Password?</a>
      </div>
    </form>

    <!-- Forgot Password Modal -->
    <div class="modal" tabindex="-1" :class="{ 'd-block': showForgotPassword, 'show': showForgotPassword }">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reset Password</h5>
            <button type="button" class="btn-close" @click="showForgotPassword = false" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="resetStatus.message" class="alert" :class="resetStatus.success ? 'alert-success' : 'alert-danger'">
              {{ resetStatus.message }}
            </div>
            <form @submit.prevent="handlePasswordReset">
              <div class="form-group mb-3">
                <label for="reset-email" class="form-label">Email address</label>
                <input type="email" class="form-control" id="reset-email" v-model="resetEmail" 
                  placeholder="Enter your email address">
                <div class="form-text">We'll send you a link to reset your password.</div>
              </div>
              <button type="submit" class="btn btn-primary" :disabled="resetLoading">
                <span v-if="resetLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Send Reset Link
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showForgotPassword"></div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import AuthService from '@/services/auth.service'

const emit = defineEmits(['login-success'])

const credentials = ref({
  email: '',
  password: ''
})

// Password reset related refs
const showForgotPassword = ref(false)
const resetEmail = ref('')
const resetLoading = ref(false)
const resetStatus = ref({
  success: false,
  message: ''
})

const rules = {
  credentials: {
    email: { required, email },
    password: { required, minLength: minLength(6) }
  },
  resetEmail: { required, email }
}

const v$ = useVuelidate(rules, { credentials, resetEmail })


const handleLogin = async () => {
  const isFormValid = await v$.value.credentials.$validate()
  if (!isFormValid) return

  try {
    await AuthService.login(credentials.value)
    credentials.value = { email: '', password: '' }
    v$.value.credentials.$reset()
    emit('login-success')
  } catch (error) {
    console.error('Login failed:', error)
  }
}

const handlePasswordReset = async () => {
  if (!resetEmail.value || v$.value.resetEmail.$invalid) {
    resetStatus.value = {
      success: false,
      message: 'Please enter a valid email address'
    }
    return
  }

  resetLoading.value = true
  resetStatus.value = { success: false, message: '' }

  try {
    const response = await AuthService.requestPasswordReset(resetEmail.value)
    resetStatus.value = {
      success: true,
      message: response.message || 'Password reset link has been sent to your email'
    }
    // Clear the email field after successful request
    resetEmail.value = ''
  } catch (error) {
    resetStatus.value = {
      success: false,
      message: error.response?.data?.message || 'Failed to send reset link. Please try again.'
    }
  } finally {
    resetLoading.value = false
  }
}
</script>

<style scoped>
/* Add these styles to properly display the modal */
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
