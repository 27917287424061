<template>
  <div class="container">
    <div class="row">
      <div class="col justify-content-center d-flex align-items-center mb-4">
        <img src="../assets/img/logo.png" alt="Kids" width="50" class="img-fluid mb-0">
        <h2 class="m-0">Kid's Check-in</h2>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form v-if="user && user.role === 'admin'">
          <div class="mb-3 d-flex align-items-center position-relative">
            <label for="name" class="visually-hidden">Name</label>
            <input type="text" class="form-control" id="name" placeholder="Enter Child's Name" v-model="name">
            <div class="spinner-grow spinner-grow-sm text-primary position-absolute end-0 me-2" v-if="loading"
              role="status"></div>
          </div>
        </form>
      </div>
    </div>
    <div class="row" v-if="showChildren">
      <div class="col">
        <div class="card mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="form-check">
                <input class="form-check-input me-3" type="checkbox" id="select-all" @change="onSelectAll" :checked="children.length === checkinList.length">
              </div>
              <label class="form-check-label flex-fill" for="select-all">
                <h2 class="card-title mb-0">Select All</h2>
              </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="showChildren">
      <div class="col">
        <div class="card mb-3" v-for="child in children" :key="child.id">
          <div class="card-header d-flex align-items-center">
            <div class="form-check">
              <input class="form-check-input me-3" type="checkbox" :value="child.id" :id="`child-${child.id}`" @change="onChildCheck(child.id)" :checked="checkinList.includes(child)">
            </div>
            <label class="form-check-label flex-fill" :for="`child-${child.id}`">
              <h2 class="card-title mb-0">{{ child.child_first }} {{ child.child_last }}</h2>
            </label>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label :for="`child-notes-${child.id}`" class="form-label visually-hidden">Notes:</label>
              <input type="text" class="form-control" :id="`child-notes-${child.id}`" v-model="child.notes" placeholder="Notes">
            </div>
            <div class="mb-3">
              <label :for="`child-phone-${child.id}`" class="form-label visually-hidden">Phone:</label>
              <input type="tel" class="form-control" :id="`child-phone-${child.id}`" v-model="child.phone" placeholder="Phone">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="noChildrenMessage && name.length > 3">
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{noChildrenMessage}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-end">
        <button type="button" v-if="checkinList.length > 0" class="btn btn-primary" @click="checkIn">Check-in</button>
      </div>
    </div>
    
    <!-- WebSocket status indicator -->
    <div class="row mt-3">
      <div class="col">
        <div class="alert" :class="wsStatusClass" v-if="showWsStatus">
          {{ wsStatusMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import ApiService from '@/services/api.service.js'
import 'script-loader!@/services/dymo.connect.framework.js'
import checkinLabelXml from '@/assets/labels/checkin.label.xml'
import ticketLabelXml from '@/assets/labels/ticket.label.xml'

const checkinList = ref([])
const children = ref([])
const label = ref(null)
const loading = ref(false)
const name = ref('')
const noChildrenMessage = ref('')
const printer = ref(null)
const searchTimeout = ref(null)
const ticketLabel = ref(null)
const user = ref(null)

// WebSocket related refs
const socket = ref(null)
const wsConnected = ref(false)
const wsStatusMessage = ref('Connecting to print service...')
const showWsStatus = ref(true)

const wsStatusClass = computed(() => {
  return wsConnected.value ? 'alert-success' : 'alert-warning'
})

const showChildren = computed(() => children.value.length > 0 && (name.value.length > 3 || user.value.role === 'parent'))

const checkIn = async () => {
  await ApiService.checkInChildren(checkinList.value)
  
  if (printer.value) { // Check if printer is connected to computer
    await printLabels(checkinList.value);
  } else if (wsConnected.value) { // If no printer send to print service
    socket.value.send(JSON.stringify({
      type: 'print',
      data: checkinList.value
    }))
    wsStatusMessage.value = 'Print job sent!'
    setTimeout(() => {
      wsStatusMessage.value = 'Connected to print service'
    }, 3000)
  } else {
    wsStatusMessage.value = 'Label printing failed. Please check printer connections and try again.'
    wsConnected.value = false;
  }
  
  checkinList.value = []
  name.value = ''
}

const createLabelSet = (data) => {
  const labelSet = new dymo.label.framework.LabelSetBuilder();

  data.forEach(child => {
    const { child_first, child_last, className, parent_first, parent_last, allergies, phone, notes } = child;
    const ticket = Math.floor(Math.random() * 100);
    const childName = `${child_first} ${child_last}`;
    const parentName = `${parent_first} ${parent_last}`;
    const text = `Class: ${className}\nParent: ${parentName}\nAllergies: ${allergies}\nContact: ${phone}\nNotes: ${notes}\nNumber: ${ticket}`;
    var record = labelSet.addRecord();
    record.setText("Name", childName).setText("Text", text).setText("Ticket", ticket);
  })

  return labelSet;
}

const getChildrenByUser = async () => {
  user.value = JSON.parse(localStorage.getItem('user'))
  const { email, role } = user.value
  if (role === 'parent') {
    const response = await ApiService.getChildrenByUser(email)
    children.value = response.data
  }
}

const loadLabel = () => {
  label.value = dymo.label.framework.openLabelXml(checkinLabelXml);
  ticketLabel.value = dymo.label.framework.openLabelXml(ticketLabelXml);
}

const loadPrinters = () => {
  var printers = dymo.label.framework.getLabelWriterPrinters();
  if (printers.length == 0) {
    console.log("No DYMO LabelWriter printers are installed. Install DYMO LabelWriter printers.");
    return;
  }

  printer.value = printers[0].name;
}

const onChildCheck = (childId) => {
  const child = children.value.find(child => child.id === childId);
  if (child && !checkinList.value.includes(child)) {
    checkinList.value.push(child);
  } else {
    checkinList.value = checkinList.value.filter(child => child.id !== childId);
  }
}

const printLabels = async (data) => {
  const labelSet = createLabelSet(data)

  try {
    if (!label.value && !ticketLabel.value)
      throw "Label is not loaded";

    if (!labelSet)
      throw "Label data is not loaded";

    console.log(labelSet);

    label.value.print(printer.value, '', labelSet)
    ticketLabel.value.print(printer.value, '', labelSet)
  }
  catch (e) {
    console.log(e.message || e)
  }
}

const onSelectAll = () => {
  children.value.forEach(child => onChildCheck(child.id))
}

const initWebSocket = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const wsUrl = `${protocol}//${window.location.hostname}:8443/print`;
  
  socket.value = new WebSocket(wsUrl);
  
  socket.value.onopen = () => {
    console.log('WebSocket connected');
    wsConnected.value = true;

    if (printer.value) {
      wsStatusMessage.value = `Connected to print service: ${printer.value}`;
      setTimeout(() => {
        showWsStatus.value = false;
      }, 5000);
    } else {
      wsStatusMessage.value = 'Connected to print service: No printer connected';
      wsConnected.value = false;
    }
  };
  
  socket.value.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.log('WebSocket message received:', message);
    
    if (message.type === 'print_status') {      
      if (printer.value) {
        wsStatusMessage.value = `Connected to print service: ${printer.value}`;
        setTimeout(() => {
          showWsStatus.value = false;
        }, 5000);
      } else {
        wsStatusMessage.value = 'Connected to print service: No printer connected';
        wsConnected.value = false;
      }

      showWsStatus.value = true;
    } else if (message.type === 'print_request') {
      printLabels(message.data);
    }
  };
  
  socket.value.onclose = () => {
    console.log('WebSocket disconnected');
    wsConnected.value = false;
    wsStatusMessage.value = 'Disconnected from print service';
    showWsStatus.value = true;
    setTimeout(initWebSocket, 5000);
  };
  
  socket.value.onerror = (error) => {
    console.error('WebSocket error:', error);
    wsConnected.value = false;
    wsStatusMessage.value = 'Error connecting to print service';
    showWsStatus.value = true;
  };
}

onMounted(() => {
  getChildrenByUser()
  loadLabel()
  loadPrinters()
  initWebSocket()
})

onUnmounted(() => {
  if (socket.value && socket.value.readyState === WebSocket.OPEN) {
    socket.value.close();
  }
})

watch(name, (newName) => {
  if (newName.length > 3) {
    clearTimeout(searchTimeout.value)
    searchTimeout.value = setTimeout(() => {
      loading.value = true
      ApiService.getChild(newName)
        .then(response => {
          if (response.data.message) {
            noChildrenMessage.value = response.data.message
          } else {
            children.value = response.data
            noChildrenMessage.value = ''
          }
          loading.value = false
        })
        .catch(error => console.error(error))
    }, 500)
  }
})
</script>

<style scoped>
  .container {
    max-width: 400px;
  }
  .form-check-input {
    height: 2em;
    width: 2em;
  }
</style>
