import axios from 'axios'

//const API_URL = 'https://ministrycheckin-api.local/'
const API_URL = 'https://api.ministrycheckin.com'

class ApiService {
  constructor() {
    this.api = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  // Auth methods
  async login(credentials) {
    return this.api.post('/auth/login', credentials)
  }

  async register(userData) {
    return this.api.post('/auth/register', userData)
  }

  async getUserInfo() {
    const token = localStorage.getItem('token')
    return this.api.get('/auth/user', token)
  }

  // Children methods
  async getChildren() {
    return this.api.get('/children')
  }

  async getChildrenByUser(email) {
    return this.api.get('/children', { params: { email } })
  }

  async getChild(name) {
    return this.api.get('/search', { params: { name } })
  }

  async createChild(data) {
    return this.api.post('/create-child', data)
  }

  async checkInChildren(data) {
    return this.api.post('/checkin-children', data)
  }
}

export default new ApiService()