<template>
  <div class="container-fluid p-0">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-4">
      <div class="container">
        <router-link class="navbar-brand" to="/">Ministry Check-in</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" v-if="!isAuthenticated">
              <router-link class="nav-link" to="/login">Login</router-link>
            </li>
            <li class="nav-item" v-if="!isAuthenticated">
              <router-link class="nav-link" to="/register">Register</router-link>
            </li>
            <li class="nav-item" v-if="isAuthenticated">
              <button @click="handleLogout" class="btn btn-link nav-link">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 py-5">
          <router-view @login-success="onAuthSuccess" @register-success="onAuthSuccess"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import AuthService from '@/services/auth.service'

const router = useRouter()
const isAuthenticated = ref(AuthService.isLoggedIn())

const onAuthSuccess = () => {
  isAuthenticated.value = true
  router.go('/checkin')
}

const handleLogout = () => {
  AuthService.logout()
  isAuthenticated.value = false
  router.go('/login')
}

onMounted(() => {
  if(isAuthenticated.value) {
    router.push('/checkin')
  }
})

watch(
  () => router.currentRoute.value,
  () => {
    isAuthenticated.value = AuthService.isLoggedIn()
  }
)
</script>
